import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const KummerkastenPage = () => (
  <Layout>
    <SEO title="Kummerkasten" />

    <h1 id="ueberschrift">Informationen zum Gesprächsangebot</h1>
    <Link to="/fdml_7a_kummerkasten-klartext#ueberschrift" title="Detaillierte Informationen zum Gesprächsangebot" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Die Welt scheint grausam und gemein<br />
      Wie soll das Herz da bleiben rein?!<br />
      Mit dem Ballast geht’s Dir nicht gut?!<br />
      Zum Reden fehlt jedoch der Mut?!<br />
      Es fehlt ein Tipp für dann und wann?!<br />
      Oder ein Mensch der zuhör’n kann:<br />
      Der Fantasie hat und versteht,<br />
      Dass <b><u>Dein</u></b> Problem über alles geht?!
    </p>

    <p className="poem">
      Wer will, der nutzt ein Pseudonym:<br />
      Damit bleibt alles Anonym.

    </p>
  </Layout>
              )

              export default KummerkastenPage
